import React, { FC } from "react";

import { MtgCard } from "../prefabs/card.service";

const getCosts = (card?: MtgCard) => {
  if (card?.card_faces?.length) {
    return card.card_faces.map(({ mana_cost }) => mana_cost);
  }

  return card?.mana_cost?.split(" // ") || [];
};

const getSymbols = (cost: string) =>
  cost
    .replace(/^\{/, "")
    .replace(/\}$/, "")
    .replace(/\//g, "")
    .split("}{")
    .filter(Boolean);

export interface ShowCardProps {
  name: string;
  set: string;
  count: number;
  image?: string;
  data?: MtgCard;
}

export const ShowCard: FC<ShowCardProps> = ({ data, name, image, count }) => {
  const costs = getCosts(data);

  return (
    <div
      title={data?.oracle_text}
      className="transition-all bg-mtg opacity-90 my-4 md:my-8 first:mt-0 last:mb-0 flex rounded-[10px] overflow-hidden"
    >
      <div className="w-[90px] md:w-[140px] relative bg-gray-700">
        <img
          className="object-cover w-full h-full absolute top-1/2 -translate-y-1/2"
          src={image}
        />
      </div>
      <div className="flex-1 flex flex-col relative gap-3 md:gap-6 text-2xl">
        {costs ? (
          <div className="absolute top-0 right-0 flex flex-col gap-1 md:gap-2">
            {costs.map((cost: string, index: number) => (
              <div
                className="flex justify-end gap-[0.2rem] pr-1 pt-1 md:pr-3 md:pt-3"
                key={index}
                title={cost}
              >
                {getSymbols(cost).map((symbol, symbolIndex) => (
                  <img
                    key={symbolIndex}
                    width={index ? 14 : 18}
                    height={index ? 14 : 18}
                    className={index ? "opacity-60" : undefined}
                    src={`/api/symbol/${encodeURIComponent(symbol)}.svg`}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : null}

        <div className="flex gap-2 md:gap-4 p-4 md:p-8">
          <div className="opacity-70">{count}x&nbsp;</div>
          <div className="flex flex-col gap-1 md:gap-2">
            <div className="break-word font-mtg">{name}</div>
            {data?.type_line ? (
              <div className="text-base md:text-lg opacity-70">
                {data.type_line}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
