import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

export interface UrlContextType {
  url: URL | null;
  route: string[];
  setHref: Dispatch<SetStateAction<string>>;
}

export const UrlContext = createContext<UrlContextType | null>(null);

export const createURL = (href: string) =>
  new URL(href.match(/^https?:/) ? href : `${location.origin}/${href}`);

export const useRouter = (): UrlContextType => {
  const [route, setRoute] = useState<string[]>([]);
  const [href, setHref] = useState(location.href);
  const [url, setUrl] = useState(createURL(href));

  useEffect(() => {
    const newUrl = createURL(href);
    const newRoute = newUrl.pathname.replace(/(^\/+|\/+$)/g, "").split("/");

    setUrl(newUrl);
    if (JSON.stringify(newRoute) !== JSON.stringify(route)) {
      setRoute(newRoute);
    }
  }, [href]);

  return { url, route, setHref };
};
