import React, { FC, useEffect, useRef, useState } from "react";

import { Battlefield } from "../prefabs/battlefield.prefab";
import { Field } from "../prefabs/card.prefab";
import { List } from "../hooks/useList";
import { LoadedContextType } from "../hooks/useLoaded";
import { MyScene } from "../prefabs/scene.prefab";

export const shuffle = (list: List) =>
  list.sort(() => (Math.random() - Math.random() > 0 ? 1 : -1));

export const Game: FC<{
  list: List;
  loaded: LoadedContextType["loaded"];
  setLoaded: LoadedContextType["setLoaded"];
}> = ({ list, loaded, setLoaded }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const [turn, setTurn] = useState(0);
  const [drawCards, setDrawCards] = useState<((count: number) => void) | null>(
    null,
  );

  useEffect(() => {
    if (ref.current && list.length) {
      if (loaded) {
        // canvas destroyed and later not initialized by scene has no style
        if (!ref.current.getAttribute("style")) {
          // so we need full reload
          location.reload();
        }
      } else {
        setLoaded(true);

        const scene = new MyScene();
        scene.init({ canvas: ref.current }).then((ok) => {
          if (ok) {
            Battlefield.library = shuffle(list.slice());
            setDrawCards(() => (count: number) => {
              Battlefield.drawCards(scene, count);
            });
          }
        });
      }
    }
  }, [ref.current, list, loaded]);

  useEffect(() => {
    if (drawCards && !turn) {
      // mulligan to 7
      drawCards(7);
      // start
      setTurn(1);
    }
  }, [drawCards, turn]);

  return (
    <>
      <canvas
        id="canvas"
        ref={ref}
        className="absolute top-0 right-0 bottom-0 left-0 max-w-full max-h-full z-20"
      />
      {turn ? (
        <div className="flex items-start justify-center gap-2 absolute z-30 top-4 left-4 text-base">
          {drawCards ? (
            <>
              <button
                className="wowflatbtn !p-2 midnight rounded-md border"
                onClick={() => {
                  // end turn
                  setTurn(turn + 1);
                  // untap
                  [...Battlefield.cards].forEach((card) => {
                    if (card.field === Field.STACK) {
                      Battlefield.moveCard(card, Field.GRAVEYARD);
                    } else {
                      Battlefield.setTapCard(card, false);
                    }
                  });
                  // draw
                  drawCards(1);
                }}
              >
                End Turn {turn}
              </button>
              <button
                className="wowflatbtn !p-2 midnight rounded-md border"
                onClick={() => {
                  // draw
                  drawCards(1);
                }}
              >
                Draw
              </button>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
