import { LoadedContext, useLoaded } from "../hooks/useLoaded";
import { UrlContext, useRouter } from "../hooks/useRouter";

import { Navbar } from "./Navbar";
import { Page } from "./Page";
import React from "react";

export default function App() {
  const router = useRouter();
  const loaded = useLoaded();

  return (
    <LoadedContext.Provider value={loaded}>
      <UrlContext.Provider value={router}>
        <section id="stars-container" className="z-10">
          <div id="stars1"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
        </section>
        <Navbar>
          <Page />
        </Navbar>
      </UrlContext.Provider>
    </LoadedContext.Provider>
  );
}
