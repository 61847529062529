import * as PIXI from "pixi.js";

import { Resources, Sprite } from "@pietal.dev/engine";

import { CardService } from "./card.service";
import { Texture } from "pixi.js";

export class CardSprite extends Sprite {
  static getSkew(y: number) {
    const minScale = 0.725;
    const maxScale = 0.825;

    return minScale + (y / innerHeight) * (maxScale - minScale);
  }

  static getScale(y: number) {
    return Math.hypot(innerWidth, innerHeight) * 0.0002 * this.getSkew(y);
  }

  static async getTexture(name: string, set: string): Promise<Texture> {
    const card = await CardService.getCard(name, set);
    const url = CardService.getImageUrl(card);
    const texture = await Resources.loadResource(url);
    if (texture) {
      texture.source.scaleMode = "linear";
      return texture;
    }

    return PIXI.Texture.WHITE;
  }
}
