import { Dispatch, SetStateAction, createContext, useState } from "react";

export interface LoadedContextType {
  loaded: boolean;
  setLoaded: Dispatch<SetStateAction<boolean>>;
}

export const LoadedContext = createContext<LoadedContextType | null>(null);

export const useLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  return { loaded, setLoaded };
};
