import { CardService, MtgCard } from "./card.service";
import { Vector, distance } from "detect-collisions";

import { Battlefield } from "./battlefield.prefab";
import { CardSprite } from "./card.sprite.prefab";
import { GameObject } from "@pietal.dev/engine";
import { MyScene } from "./scene.prefab";

export type ListCard = { name: string; set: string };

export type List = ListCard[];

export enum Field {
  LIBRARY = "library",
  HAND = "hand",
  STACK = "stack",
  LANDS = "lands",
  PERMANENTS = "permanents",
  GRAVEYARD = "graveyard",
  EXILE = "exile",
}

export class Card extends GameObject {
  static cardPartsRegex =
    /(?:([0-9]+)x? )?([\w\s\-'",\/]+) ?(?:<([0-9]+)>)? ?(?:\[([\da-zA-Z]+)\])?/;

  index = 0; // index in battlefield card field array
  target: Vector & { angle: number; alpha: number } = {
    x: innerWidth / 2,
    y: innerHeight / 2,
    alpha: 1,
    angle: 0,
  };
  tapped = false;
  field: Field = Field.HAND;
  set: string;
  data?: MtgCard;
  sprite?: CardSprite;

  static getType(card: Card): string {
    return card.data.type_line || "";
  }

  static isLand(card: Card) {
    return Card.getType(card).includes("Land");
  }

  static isPermanent(card: Card) {
    return Card.getType(card).match(
      /artifact|battle|creature|enchantment|planeswalker/i,
    );
  }

  static getCardParts(cardName: string) {
    const [_fullMatch, count, name] = cardName.match(Card.cardPartsRegex) || [
      undefined,
      1,
      cardName,
    ];

    return { count, name };
  }

  constructor({ name, set }: ListCard) {
    super(Card.getCardParts(name).name, innerWidth / 2, innerHeight / 2);

    this.set = set;
  }

  lerp(from: number, to: number, delta: number) {
    const result = from + delta * (to - from);
    return (result > to && from < to) || (result < to && from > to)
      ? to
      : result;
  }

  tween(deltaTime: number) {
    const { alpha, angle, x, y } = this.target;
    const speed = 0.1 * Math.min(1, deltaTime);
    this.x = this.lerp(this.x, x, speed);
    this.y = this.lerp(this.y, y, speed);
    this.sprite.alpha = this.lerp(this.sprite.alpha, alpha, speed);
    this.sprite.angle = this.lerp(this.sprite.angle, angle, speed);
  }

  updateSprite() {
    const scaledX = this.x / innerWidth - 0.5;
    const scaledY = this.y / innerHeight;
    this.sprite.zIndex = 1000 * (scaledX + scaledY + this.index);
    this.sprite.scale.set(CardSprite.getScale(this.sprite.y));
  }

  update(deltaTime: number) {
    this.tween(deltaTime);
    this.updateSprite();
    super.update(deltaTime);
  }

  async init(scene: MyScene) {
    if (scene.pixi) {
      const texture = await CardSprite.getTexture(this.label, this.set);
      this.sprite = new CardSprite(this, texture);
      this.sprite.anchor.set(0.5);
      this.sprite.position.set(innerWidth / 2, innerHeight / 2);
      this.sprite.scale.set(CardSprite.getScale(innerHeight / 2));
      this.sprite.alpha = 0;
      this.sprite.interactive = true;
      this.sprite.visible = false;

      this.sprite.addEventListener("pointerdown", () => {
        if (this.field === Field.HAND) {
          Battlefield.moveCard(this);
        } else if (this.field === Field.GRAVEYARD) {
          Battlefield.moveCard(this, Field.HAND);
        } else if (Card.isPermanent(this) || Card.isLand(this)) {
          Battlefield.setTapCard(this, !this.tapped);
        } else {
          Battlefield.moveCard(this, Field.GRAVEYARD);
        }
      });
    }

    scene.addChild(this);

    CardService.getCard(this.label, this.set).then((data) => {
      this.data = data;
      Battlefield.addCard(this);
      this.sprite.visible = true;
    });

    return this;
  }
}
