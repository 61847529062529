import { useEffect, useState } from "react";

export type ListCard = { name: string; set: string };

export type List = ListCard[];

export const getList = async (type: string, id: string) => {
  switch (type) {
    case "tappedout": {
      const response = await fetch(`/api/deck/tappedout/${id}`);
      const text = await response.text();
      const tappedoutRegex = /^(\w+),(\d+),(?:"([^"]+)"|([^,]+)),([^,]+)?/;

      return text
        .trim()
        .split("\n")
        .slice(1)
        .reduce((list: List, line: string) => {
          const [_all, pile, count, name1, name2, set] = line.match(
            tappedoutRegex,
          ) || [undefined, undefined, 0, "", "", ""];

          if (pile === "main") {
            for (let i = 0; i < Number(count); i++) {
              list.push({ name: name1 || name2, set: set || "" });
            }
          }

          return list;
        }, []);
    }
    case "mtggoldfish": {
      const response = await fetch(`/api/deck/mtggoldfish/${id}`);
      const text = await response.text();

      return text
        .trim()
        .split(/\r?\n\r?\n/)[0]
        .split(/\r?\n/)
        .map((line) => line.split(/\[|\(|</)[0].trim())
        .reduce((list: List, line: string) => {
          const [_match, count, name] = line.match(/(\d+)\s([\w\s\-'",]+)/) || [
            undefined,
            0,
            "",
          ];

          for (let i = 0; i < Number(count); i++) {
            list.push({ name, set: "" });
          }

          return list;
        }, []);
    }
    default:
      return [];
  }
};

export const useList = (route: string[]) => {
  const [, type, id] = route;
  const [list, setList] = useState<List>([]);

  useEffect(() => {
    getList(type, id).then(setList);
  }, [type, id]);

  return list;
};
