import React, {
  Dispatch,
  FC,
  MouseEvent,
  ReactNode,
  SetStateAction,
} from "react";

export const Link: FC<{
  setHref: Dispatch<SetStateAction<string>>;
  href: string;
  children: ReactNode[] | ReactNode;
}> = ({ href, setHref, children }) => {
  const onClick = (href: string) => {
    return (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      setHref(href);
      history.pushState(undefined, "", href);
    };
  };

  return (
    <a href={href} onClick={onClick(href)}>
      {children}
    </a>
  );
};
