import { Card, Field, List } from "./card.prefab";
import { Vector, deg2rad, rad2deg } from "detect-collisions";

import { CardSprite } from "./card.sprite.prefab";
import { MyScene } from "./scene.prefab";

export class Battlefield {
  static cards: Card[] = [];
  static library: List = [];

  static getCardCastField(card: Card): Field {
    return Card.isLand(card)
      ? Field.LANDS
      : Card.isPermanent(card)
        ? Field.PERMANENTS
        : Field.STACK;
  }

  static getCardScaleX(
    card: { field: Card["field"]; target: { y: number } },
    size: number,
  ) {
    return (
      CardSprite.getSkew(card.target.y) *
      (card.field === Field.HAND ? Math.min(0.67 / size, 0.09) : 0.12)
    );
  }

  static getCardX(card: Card, { index, size }) {
    const scaleX = Battlefield.getCardScaleX(card, size);
    const half = (size - 1) / 2;
    return innerWidth / 2 + (index - half) * innerWidth * scaleX;
  }

  static addCard(card: Card, field = Field.HAND) {
    if (!card) {
      return;
    }

    if (Battlefield.cards.includes(card)) {
      Battlefield.cards.indexOf(card);
    }

    card.field = field;
    Battlefield.cards.push(card);
    Battlefield.setTapCard(card, false);
    Battlefield.repositionCards(field);
  }

  static async drawCard(scene: MyScene) {
    const next = Battlefield.library.pop();
    if (next) {
      const card = new Card(next);
      await card.init(scene);
    }
  }

  static drawCards(scene: MyScene, count: number) {
    const promises = [];
    for (let i = 0; i < count; i++) {
      promises.push(Battlefield.drawCard(scene));
    }
    Promise.all(promises).then((cards) => {
      cards.forEach((card) => {
        Battlefield.addCard(card);
      });
    });
  }

  static setTapCard(card: Card, tapped = false) {
    if (card.field === Field.HAND) {
      return;
    }
    card.tapped = tapped;
    card.target.angle = tapped ? 45 : 0;
  }

  static moveCard(card: Card, field = Battlefield.getCardCastField(card)) {
    const index = Battlefield.cards.indexOf(card);
    if (index !== -1) {
      const old = card.field;
      Battlefield.cards.splice(index, 1);
      Battlefield.repositionCards(old);
    }
    Battlefield.addCard(card, field);
  }

  static getCardPosition(card: Card): Vector & { angle: null | number } {
    const array = Battlefield.cards.filter(
      (other) => other.field === card.field,
    );
    const index = array.indexOf(card);
    const size = array.length;
    const half = (size - 1) / 2;

    let x = 0;
    let y = 0;
    let angle = null;

    switch (card.field) {
      case Field.STACK: {
        y = innerHeight * 0.25;
        x = Battlefield.getCardX(card, { index, size });
        break;
      }
      case Field.PERMANENTS: {
        y = innerHeight * 0.5;
        x = Battlefield.getCardX(card, { index, size });
        break;
      }
      case Field.LANDS: {
        y = innerHeight * 0.75;
        x = Battlefield.getCardX(card, { index, size });
        break;
      }
      case Field.GRAVEYARD: {
        const scale = CardSprite.getScale(innerHeight);
        const { width, height } = card.sprite.texture;
        y = innerHeight - (scale * height) / 2;
        x = innerWidth - (scale * width) / 2;
        break;
      }
      case Field.HAND: {
        const variance = (2 * (index - half)) / size;
        const arc = Math.sin(deg2rad(30 * variance));
        const cardY = innerHeight * (0.9 + 0.1 * Math.pow(variance, 2));

        y = cardY;
        x = Battlefield.getCardX(card, { index, size });
        angle = rad2deg(arc);
        break;
      }
    }

    return { x, y, angle };
  }

  static repositionCards(field: Field) {
    const array = Battlefield.cards.filter((other) => other.field === field);

    array.forEach((card) => {
      const { x, y, angle } = Battlefield.getCardPosition(card);
      card.target.x = x;
      card.target.y = y;
      if (angle !== null) {
        card.target.angle = angle;
      }
    });

    Battlefield.cards.sort((a, b) =>
      a.target.x / innerWidth + a.target.y / innerHeight <
      b.target.x / innerWidth + b.target.y / innerHeight
        ? -1
        : 1,
    );

    Battlefield.cards.forEach((card, index) => {
      card.index = index / Battlefield.cards.length;
    });
  }
}
