import React, { FC } from "react";

export const Logo: FC = () => (
  <h1 className="flex items-center justify-center gap-4">
    <div className="w-[90px] h-[90px] relative rounded-lg overflow-hidden shadow-2xl">
      <img
        className="object-cover w-full h-full absolute top-1/2 -translate-y-1/2"
        src="/logo.jpg"
      />
    </div>
    <span>Magic</span>
  </h1>
);
