import { CardService, MtgCard } from "../prefabs/card.service";
import React, { FC, useContext, useEffect, useState } from "react";
import { ShowCard, ShowCardProps } from "./ShowCard";

import { Link } from "./Link";
import { List } from "../prefabs/card.prefab";
import { UrlContext } from "../hooks/useRouter";

const getType = (data?: MtgCard) =>
  (data?.type_line || "")
    .split(" — ")[0]
    .split(" ")
    .reverse()
    .join(" ")
    .replace("Land", "ZZZ");

const sortByType = ({ data: a }: ShowCardProps, { data: b }: ShowCardProps) => {
  const typeA = getType(a);
  const typeB = getType(b);
  if (typeA === typeB) {
    return 0;
  }

  return typeA < typeB ? -1 : 1;
};

const sortByCMC = ({ data: a }: ShowCardProps, { data: b }: ShowCardProps) => {
  const cmcA = a?.cmc || 0;
  const cmcB = b?.cmc || 0;
  if (cmcA === cmcB) {
    return 0;
  }

  return cmcA < cmcB ? -1 : 1;
};

const sortByName = ({ name: a }: ShowCardProps, { name: b }: ShowCardProps) =>
  a < b ? -1 : 1;

export const Show: FC<{ list: List; type: string; id: string }> = ({
  list,
  type,
  id,
}) => {
  const router = useContext(UrlContext)!;
  const setHref = router.setHref;
  const [show, setShow] = useState<ShowCardProps[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const uniqueCards = list.reduce((cards: ShowCardProps[], { name, set }) => {
      const another = cards.find(
        (card: ShowCardProps) => card.name.toUpperCase() === name.toUpperCase(),
      );

      if (another) {
        another.count++;
      } else {
        cards.push({ count: 1, name, set });
      }

      return cards;
    }, []);

    setTotal(
      uniqueCards.reduce(
        (count: number, card: ShowCardProps) => count + card.count,
        0,
      ),
    );

    const showCards: ShowCardProps[] = [];

    uniqueCards.forEach(({ count, name, set }) => {
      CardService.getCard(name, set).then((data) => {
        const image = CardService.getImageUrl(data, CardService.SHOW_TYPE);
        showCards.push({ count, name, data, image, set });
        showCards.sort(sortByName);
        showCards.sort(sortByCMC);
        showCards.sort(sortByType);
        setShow([...showCards]);
      });
    });
  }, [list]);

  const shown = show.reduce(
    (count: number, card: ShowCardProps) => count + card.count,
    0,
  );

  return (
    <>
      {show.length ? (
        <>
          <h2 className="text-center my-4 md:my-8">
            <Link setHref={setHref} href={`/play/${type}/${id}/`}>
              Click to Draw Hand
            </Link>
          </h2>
          <div className="columns-1 lg:columns-2 2xl:columns-3 lg:px-8 gap-2 md:gap-4">
            {show.map((card) => (
              <ShowCard {...card} key={card.name} />
            ))}
          </div>
          <h2 className="text-center my-4 md:my-8">
            Loaded {shown}/{total} Cards
          </h2>
        </>
      ) : (
        <h2 className="text-center my-4 md:my-8">Loading Cards</h2>
      )}
    </>
  );
};
