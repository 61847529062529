import React, { FC, useContext } from "react";

import { Game } from "./Game";
import { Home } from "./Home";
import { LoadedContext } from "../hooks/useLoaded";
import { Show } from "./Show";
import { UrlContext } from "../hooks/useRouter";
import { useList } from "../hooks/useList";

export const Page: FC = () => {
  const router = useContext(UrlContext)!;
  const { loaded, setLoaded } = useContext(LoadedContext)!;
  const [module, type, id] = router.route;
  const list = useList([module, type, id]);

  switch (module) {
    case "show":
      return <Show list={list} type={type} id={id} />;
    case "play":
      return <Game list={list} loaded={loaded} setLoaded={setLoaded} />;
    case undefined:
      return null;
    default:
      return <Home />;
  }
};
