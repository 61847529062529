import React, { FC, ReactNode, useContext } from "react";

import { Link } from "./Link";
import { UrlContext } from "../hooks/useRouter";

const getType = (text: string) => {
  switch (text) {
    case "tappedout":
      return "T/O";

    case "mtggoldfish":
      return "GoldFish";

    default:
      return text;
  }
};

const wrapText = (text: string) =>
  text
    .split("")
    .map((char) => (
      <span className="leading-6 landscape:-rotate-90 landscape:w-6 landscape:h-6 landscape:inline-block">
        {char}
      </span>
    ));

export const Navbar: FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const router = useContext(UrlContext)!;
  const [module, type, id] = router.route;
  const setHref = router.setHref;
  const container =
    module === "play"
      ? ""
      : "items-center justify-center mx-auto sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl px-4 lg:px-8";

  return (
    <div className="min-h-screen flex flex-col landscape:flex-row text-2xl lg:text-3xl">
      <div className="transition-all flex shrink z-50 overflow-auto w-full h-auto p-4 landscape:w-auto landscape:h-full landscape:[writing-mode:vertical-lr] bg-mtg shadow-2xl">
        <div className="flex-1 flex landscape:flex-row gap-6 justify-start items-center small-caps text-nowrap text-center">
          <span>🧚</span>
          <Link setHref={setHref} href="/">
            {wrapText("Magic")}
          </Link>
          {["show", "play"].includes(module) ? (
            <>
              <Link
                setHref={setHref}
                href={
                  module === "show"
                    ? `/play/${type}/${id}/`
                    : `/show/${type}/${id}/`
                }
              >
                {wrapText(module)}
              </Link>
              <span>{wrapText(getType(type))}</span>
              <span>{wrapText(id)}</span>
            </>
          ) : (
            <span>{wrapText("Home")}</span>
          )}
        </div>
      </div>
      <div
        className={`relative z-20 flex grow flex-col items-center justify-center ${container}`}
      >
        {children}
      </div>
    </div>
  );
};
