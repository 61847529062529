import * as PIXI from "pixi.js";

import { Card } from "./card.prefab";
import { Scene } from "@pietal.dev/engine";
import { fromEvent } from "rxjs";
import { takeUntil } from "rxjs/internal/operators/takeUntil";

export class MyScene extends Scene {
  get pixiStage(): PIXI.Container {
    return this.pixi.stage;
  }

  constructor() {
    const queryParams = Scene.getQueryParams();
    super({
      visible: true,
      showFPS: "fps" in queryParams,
      debug: "debug" in queryParams,
    });
  }

  async init({ canvas }: { canvas?: HTMLCanvasElement }): Promise<boolean> {
    const ok = await super.init({
      resizeTo: window,
      autoDensity: true,
      autoStart: false,
      sharedTicker: false,
      backgroundAlpha: 0,
      resolution: 2,
      canvas,
    });

    if (!ok) {
      return false;
    }

    let lastWidth = innerWidth;
    let lastHeight = innerHeight;

    fromEvent(window, "resize")
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const scaleX = innerWidth / lastWidth;
        const scaleY = innerHeight / lastHeight;

        this.children.forEach((card) => {
          if (card instanceof Card) {
            card.x *= scaleX;
            card.y *= scaleY;
            card.target.x *= scaleX;
            card.target.y *= scaleY;
          }
        });

        lastWidth = innerWidth;
        lastHeight = innerHeight;
      });

    fromEvent(window, "contextmenu")
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => event.preventDefault());

    this.start();

    return true;
  }
}
