import "./index.scss";

import React, { StrictMode } from "react";

import App from "./components/App";
import { createRoot } from "react-dom/client";
import register from "offline-worker";

const rootElement = document.getElementById("root");

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

  register();
}
