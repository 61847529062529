import React, { FC, FormEvent, useContext } from "react";

import { Link } from "./Link";
import { Logo } from "./Logo";
import { UrlContext } from "../hooks/useRouter";

export const Home: FC = () => {
  const router = useContext(UrlContext)!;
  const setHref = router.setHref;

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const url = Object.fromEntries(formData).url as string;
    const idRegex = /(tappedout|mtggoldfish)(?:[^\/]*\/)*([^?#\/]+)/;
    const [match, type, id] = url.match(idRegex) || [undefined, undefined];

    if (!match) {
      return;
    }

    const href = `${location.origin}/show/${type}/${id}/`;
    setHref(href);
    history.pushState(undefined, "", href);
  };

  return (
    <div className="max-w-full flex flex-col gap-8 items-center justify-center">
      <Logo />
      <h2>Deck Address</h2>
      <form className="max-w-full flex gap-4 items-center" onSubmit={onSubmit}>
        <input
          name="url"
          className="w-full wowflatbtn white rounded-md border"
          type="text"
          placeholder="paste url"
        />
        <button type="submit" className="wowflatbtn midnight rounded-md border">
          Show
        </button>
      </form>
      <h2>Examples</h2>
      <div className="flex flex-col gap-2 items-center text-center">
        <div>
          <Link setHref={setHref} href="/show/mtggoldfish/6415999/">
            Pauper Dimir Fairies
          </Link>
        </div>
        <div>
          <Link setHref={setHref} href="/show/mtggoldfish/6597029/">
            Pauper Simic Infect
          </Link>
        </div>
        <div>
          <Link setHref={setHref} href="/show/mtggoldfish/6531995/">
            Ur-Dragon EDH
          </Link>
        </div>
      </div>
    </div>
  );
};
